<template>
  <section>
    <div class="overlay" v-show="modal"></div>
    <transition name="bounce">
      <div class="modal with-bg" v-show="modal === 'rules'">
        <div class="header">
          <div class="header-title" v-html="t('rules_title')"></div>
          <img class="close" @click="$store.commit('russian_roulette/set__modal', '')" :src="require('@/assets/img/close.svg')" alt="close" />
        </div>
        <div class="content">
          <p class="modal-title" v-html="t('about')"></p>
          <div class="description">
            <p v-html="t('rules_2')"></p>
            <p v-html="t('rules_3')"></p>
          </div>
          <p class="modal-title" v-html="t('you_need')"></p>
          <ol class="description">
            <li v-html="t('rules_4')"></li>
            <li v-html="t('rules_5')"></li>
            <li v-html="t('rules_6')"></li>
          </ol>
          <p class="modal-title" v-html="t('game_instructions')"></p>
          <ol class="description">
            <li v-html="t('rules_7')"></li>
            <li v-html="t('rules_8')"></li>
            <li v-html="t('rules_9')"></li>
            <li>
              <span v-html="t('rules_10')"></span>
              <br>
              <span v-html="t('rules_11')"></span>
            </li>
            <li v-html="t('rules_12')"></li>
            <li v-html="t('rules_13')"></li>
            <li v-html="t('rules_14')"></li>
          </ol>
          <p class="description-last" v-html="t('good_luck')"></p>
        </div>
      </div>
    </transition>
    <transition name="bounce">
      <div class="modal" v-show="modal === 'gameHistory'">
        <div class="header">
        <div class="header-title" v-html="t('game_details')"></div>
          <img class="close" @click="$store.commit('russian_roulette/set__modal', '')" :src="require('@/assets/img/close.svg')" alt="close" />
        </div>
        <div class="content">
          <div class="modal-grid">
            <div class="modal-flex">
              <div class="left-title" v-html="t('game_result')"></div>
              <div v-if="modalContent.playerChoice" class="modal-bullets m-l-10">
                <div v-for="(choiceEl, a) in Array.from(modalContent.playerChoice)" :key="a" :class="{ bulletNo: 0 === choiceEl, 'bulletRandom': modalContent.random === a+1 }">
                  <img :src="require('@/assets/img/bullet-small.svg')" alt="Bullet" />
                </div>
              </div>
            </div>
            <div class="modal-flex">
              <div class="left-title" v-html="t('bet')"></div>
              <div v-if="modalContent" class="p-l-10">{{ modalContent.betAmount }} <img :src="require('@/assets/img/waves.svg')" alt="Waves" class="modal-waves"></div>
            </div>
            <div class="modal-flex">
              <div class="left-title" v-html="t('won')"></div>
              <div v-if="modalContent" v-show="modalContent.result === 'won'" class="p-l-10">
                {{ modalContent.winAmount }} <img :src="require('@/assets/img/waves.svg')" alt="Waves" class="modal-waves">
              </div>
              <div v-if="modalContent" v-show="modalContent.result === 'lost'" class="p-l-10">
                <span class="opacity-5">—</span>
              </div>
            </div>
          </div>
          <a v-if="config.mode === 'testnet'" class="smart-contract-btn" target="_blank" :href="`https://wavesexplorer.com/testnet/tx/${modalContent.id}`" v-html="t('smart_contract')"></a>
          <a v-if="config.mode === 'mainnet'" class="smart-contract-btn" target="_blank" :href="`https://wavesexplorer.com/tx/${modalContent.id}`" v-html="t('smart_contract')"></a>
          <a v-if="config.mode === 'testnet'" class="open-smart-contract" target="_blank" :href="`https://wavesexplorer.com/testnet/address/${modalContent.address}`" v-html="t('show_player')"></a>
          <a v-if="config.mode === 'mainnet'" class="open-smart-contract" target="_blank" :href="`https://wavesexplorer.com/address/${modalContent.address}`" v-html="t('show_player')"></a>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  computed: {
    modal() { return this.$store.getters['russian_roulette/get__modal'] },
    config() { return this.$store.getters['russian_roulette/get__config'] },
    modalContent() { return this.$store.getters['russian_roulette/get__modalContent'] },
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') this.$store.commit('russian_roulette/set__modal', '')
    })
  }
}
</script>


<style scoped lang="sass">
@import '@/assets/sass/modals.sass'
</style>