<template>
  <div class="ui">
    <div class="top-ui">
      <aside class="column-left">
        <div class="logo-block">
          <img :src="require('@/assets/img/logo.svg')" class="logo" alt="Logo" />
          <h1>Russian<br>Roulette</h1>
        </div>
        <button v-tippy="{ placement: 'left' }" :content="t('coming_soon')" @click="openPlatform" class="pulse">
          <span><img src="https://cdn.lucky-dapps.com/images/logo.svg" alt="Lucky dApps" /></span>
          <p class="btn-text">Lucky dApps</p>
        </button>
        <button v-tippy="{ placement: 'left' }" :content="t('project_news')" @click="openTG">
          <span><img :src="require('@/assets/img/telegram.svg')" alt="Telegram" /></span>
          <p class="btn-text" v-html="t('telegram')"></p>
        </button>
        <button v-tippy="{ placement: 'left' }" :content="t('how_to_play')" @click="$store.commit('russian_roulette/set__modal', 'rules')" class="startRules">
          <span><img :src="require('@/assets/img/rules.svg')" alt="Rules" /></span>
          <p class="btn-text" v-html="t('rules')"></p>
        </button>
        <button v-tippy="{ placement: 'left' }" :content="`${t('change_mode')} ${config.mode === 'testnet' ? 'Mainnet' : 'Testnet'}`" @click="$store.commit('russian_roulette/toggle__mode')" :class="{ pulse: online }">
          <span><img :src="require('@/assets/img/mode.svg')" :alt="config.mode" /></span>
          <p class="btn-text">{{ config.mode === 'testnet' ? 'Testnet' : 'Mainnet' }}</p>
        </button>
        <div class="settings">
          <img class="lang-icon" v-tippy :content="t('translate_to_en')" @click="$store.commit('app/set__lang', 'en')" v-if="lang === 'ru'" :src="require('@/assets/img/rus.svg')" alt="Russian Language" />
          <img class="lang-icon" v-tippy :content="t('translate_to_ru')" @click="$store.commit('app/set__lang', 'ru')" v-if="lang === 'en'" :src="require('@/assets/img/eng.svg')" alt="English Language" />
          <img class="mute-icon" v-tippy :content="t('mute')" @click="$store.commit('app/toggle__mute')" v-if="!mute" :src="require('@/assets/img/mute.svg')" alt="Sound Icon" />
          <img class="mute-icon" v-tippy :content="t('unmute')" @click="$store.commit('app/toggle__mute')" v-if="mute" :src="require('@/assets/img/muted.svg')" alt="Sound Icon Muted" />
        </div>
      </aside>
      <div class="middle-block">
        <h2 class="title">{{ game.state !== '' ? this.t('state.'+game.state) : t('state.default') }}</h2>
        <div class="wheel" :style="`transform: rotateZ(${game.state !== '' ? game.deg : 0}deg); transition: transform ${this.game.deg_s}s ${game.deg_s === 2 ? 'linear' : 'cubic-bezier(0.15, 0.15, 0, 1)'} 0s`">
          <div class="result" :class="game.state"></div>
          <img class="fortune-wheel" :src="require('@/assets/img/revolver.svg')" />
          <button class="bullet-block" :class="['bullet-'+b]" @click="toggleBullet(b-1)" v-for="b in 6" :key="b">
            <transition name="bounce">
              <div v-show="game.playerChoice[b-1] != 0" class="bullet"></div>
            </transition>
          </button>
        </div>
      </div>
      <aside class="column-right">
        <div class="details-right">
          <div class="section">
            <p class="header-text" v-html="t('Winning_Chance')"></p>
            <p class="value-text">{{ chanceAnimated }}</p>
          </div>
          <div class="section">
            <p class="header-text" v-html="t('Winning_Coefficient')"></p>
            <p class="value-text">
              <span class="lowercase">x</span>
              <span>{{ coefficientAnimated }}</span>
            </p>
          </div>
          <div class="section">
            <p class="header-text" v-html="t('You_May_Win')"></p>
            <p class="value-text">
              <span>{{ mayWinAnimated }}</span>
              <img v-tippy content="Waves" :src="require('@/assets/img/waves.svg')" alt="Waves" class="details-waves">
            </p>
          </div>
        </div>
      </aside>
    </div>
    <div class="middle-block">
      <transition name="bounce">
        <div v-show="game.state !== ''" class="waiting-box">
          <div class="window-flex">
            <div class="waiting-title">{{ game.state === '' ? t('waiting_short') : (game.state === 'waiting' ? t('waiting') : t('state.'+game.state) )}}</div>
            <div class="tx-text">
              {{ game.betAmount }}
              <img :src="require('@/assets/img/waves.svg')" alt="Waves" class="wave" />
            </div>
          </div>
          <a class="contract-btn pulse" v-show="config.mode === 'testnet'" target="_blank" :href="`${config[config.mode].ui}/tx/${game.id}`" v-html="t('smart_contract')"></a>
          <a class="contract-btn pulse" v-show="config.mode === 'mainnet'" target="_blank" :href="`${config[config.mode].ui}/tx/${game.id}`" v-html="t('smart_contract')"></a>
        </div>
      </transition>

      <h2 class="title" v-html="t('your_bet')"></h2>

      <div class="bets">
        <div v-for="amount of amounts" :key="amount" @click="setAmount(amount)" class="bet" :class="{ active: game.betAmount === amount }"><span>{{ amount }}</span><p>Waves</p></div>
      </div>
      <template v-if="canPlay">
        <button @click="bet" class="action play pulse" :disabled="game.state !== ''" v-html="t('play')"></button>
      </template>
      <template v-else>
        <div v-if="global_config.WavesKeeper === 'undefined'" class="install-waves-keeper">
          <p v-html="t('install_keeper')"></p>
          <a target="_blank" :href="global_config.wavesKeeperLink" class="install-btn">{{ t('install') }} WavesKeeper</a>
        </div>
        <template v-else>
          <button class="action offline" v-if="!online" disabled v-html="t('server_offline')"></button>
          <button class="action wrong_network" v-if="wrongNet" disabled>{{ t('switch_to') }} <span class="capitalize">{{ config.mode }}</span></button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { TweenLite } from 'gsap'

import { setCharAt } from '@/logic/helper'

import putAudio from '@/assets/audio/put.mp3'
import looseAudio from '@/assets/audio/loose.mp3'
import winAudio from '@/assets/audio/win.mp3'
import spinAudio from '@/assets/audio/spin.mp3'

export default {
  data() {
    return {
      amounts: [0.2, 0.5, 1, 2, 4],
      game: {
        id: '',
        playerChoice: '010101',
        betAmount: 1,
        chance: 50,
        coefficient: 1.90,
        mayWin: 1.90,
        state: '',
        deg: 0,
        deg_s: 0
      },
      coefficientAnimatedNum: 1.90,
      mayWinAnimatedNum: 1.90,
      chanceAnimatedNum: 50,
      media: {
        putSound: new Audio(putAudio),
        looseSound: new Audio(looseAudio),
        winSound: new Audio(winAudio),
        spinSound: new Audio(spinAudio)
      },
      wavesKeeperData: {},
      setCharAt
    }
  },
  methods: {
    async API(url) {
      try {
        let { data } = await axios.get(url)
        return data
      } catch(e) {
        console.log(e)
      }
    },
    setAmount(amount) {
      if (this.game.state === '') this.game.betAmount = amount
    },
    updateDetails() {
      if (this.getBulletsCount === 5) { this.game.coefficient = 3.96; this.game.chance = 16.67 }
      if (this.getBulletsCount === 4) { this.game.coefficient = 2.46; this.game.chance = 33.33 }
      if (this.getBulletsCount === 3) { this.game.coefficient = 1.9; this.game.chance = 50.00 }
      if (this.getBulletsCount === 2) { this.game.coefficient = 1.42; this.game.chance = 66.67 }
      if (this.getBulletsCount === 1) { this.game.coefficient = 1.14; this.game.chance = 83.33 }
      this.game.mayWin = parseFloat((this.game.betAmount*this.game.coefficient).toFixed(2))
    },
    toggleBullet(b) {
      if (this.game.state !== '') return

      let false0true1 = 1 - this.game.playerChoice[b]

      if (this.getBulletsCount === 1 && false0true1 === 0) return
      if (this.getBulletsCount === 5 && false0true1 === 1) {
        if (b === 0) this.game.playerChoice = this.setCharAt(this.game.playerChoice, 3, 0)
        if (b === 1) this.game.playerChoice = this.setCharAt(this.game.playerChoice, 4, 0)
        if (b === 2) this.game.playerChoice = this.setCharAt(this.game.playerChoice, 5, 0)
        if (b === 3) this.game.playerChoice = this.setCharAt(this.game.playerChoice, 0, 0)
        if (b === 4) this.game.playerChoice = this.setCharAt(this.game.playerChoice, 1, 0)
        if (b === 5) this.game.playerChoice = this.setCharAt(this.game.playerChoice, 2, 0)
      }

      if (!this.mute) this.media.putSound.play()

      this.game.playerChoice = this.setCharAt(this.game.playerChoice, b, false0true1)
    },
    endGame(win, random) {
      if (this.game.state !== 'waiting') return

      this.game.deg_s = 2

      const degs = [30, 330, 270, 210, 150, 90]

      this.game.deg += degs[random-1]

      this.game.state = win ? 'win' : 'lost'

      setTimeout(() => (this.game.state = '', this.game.deg_s = 0, this.game.deg = 0), 5000)

      if (!this.mute) win ? this.media.winSound.play() : this.media.looseSound.play()
    },
    startSpin() {
      this.game.state = 'waiting'
      this.game.deg_s = 30
      this.game.deg = 360 * 14
      if (!this.mute) this.media.spinSound.play()
    },
    bet() {
      if (typeof window.WavesKeeper === 'undefined') return alert('WavesKeeper not installed')

      const txData = {
        type: 16,
        data: {
          fee: {
            tokens: this.config.feeAmount,
            assetId: 'WAVES'
          },
          dApp: this.config[this.config.mode].dAppAddress,
          call: {
            function: 'bet',
            args: [{ type: 'string', value: this.game.playerChoice }]
          },
          payment: [{ assetId: 'WAVES', tokens: parseFloat(this.game.betAmount) + parseFloat(this.config.feeAmount) }]
        }
      }

      window.WavesKeeper.signAndPublishTransaction(txData).then(tx => {
        tx = JSON.parse(tx)
        console.log('%c TX with your bet was send', 'background: #3c7cfe; color: #ffffff; border-radius: 4px; padding: 2px 4px 2px')
        console.log(tx)
        this.game.id = tx.id
        this.startSpin()
      }).catch((error) => {
        console.log('%c Something went wrong with bet', 'background: #fa305c; color: #ffffff; border-radius: 4px; padding: 2px 4px 2px')
        console.error(error)
      })
    },
    checkIsGameEnd(history) {
      for (let h of history) {
        if (h.id !== this.game.id || h.result === 'submitted') return
        this.endGame(h.gameData.split('_')[0] === '03WON', h.random)
      }
    },
    openPlatform() {
      window.open('https://lucky-dapps.com', '_blank')
    },
    openTG() {
      window.open(this.global_config.tgLink, '_blank')
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    without(array, what) {
      return array.filter(element => element !== what)
    },
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace)
    }
  },
  computed: {
    wrongNet() {
      if (!this.user?.account) return false
      return this.user?.account?.network !== this.config.mode
    },
    canPlay() {
      if (!this.online) return false
      if (this.wrongNet) return false
      if (typeof this.global_config.WavesKeeper === 'undefined') return false

      return true
    },
    getBulletsCount() {
      const s = this.game.playerChoice.replaceAll('0', '')
      return s.length
    },
    getBetAmount() { return this.game.betAmount },
    getCoefficient() { return this.game.coefficient },
    getMayWin() { return this.game.mayWin },
    getChance() { return this.game.chance },
    coefficientAnimated() { return this.coefficientAnimatedNum.toFixed(2) },
    mayWinAnimated() { return this.mayWinAnimatedNum.toFixed(2) },
    chanceAnimated() { return this.chanceAnimatedNum.toFixed(2)+'%' },
    mute() { return this.$store.getters['app/get__mute'] },
    lang() { return this.$store.getters['app/get__lang'] },
    config() { return this.$store.getters['russian_roulette/get__config'] },
    global_config() { return this.$store.getters['app/get__data'] },
    socket() { return this.$store.getters['app/get__socket'] },
    online() { return this.$store.getters['app/get__online'] },
    wavesKeeper() { return window.WavesKeeper },
    user() { return this.$store.getters['app/get__user'] }
  },
  watch: {
    wavesKeeperData(newValue) { this.$store.commit('app/set__user', newValue) },
    wavesKeeper(newValue) { this.$store.commit('app/set__waves_keeper', newValue) },
    getBulletsCount() { this.updateDetails() },
    getBetAmount() { this.updateDetails() },
    getCoefficient(newValue) { TweenLite.to(this.$data, 0.5, { coefficientAnimatedNum: newValue }) },
    getMayWin(newValue) { TweenLite.to(this.$data, 0.5, { mayWinAnimatedNum: newValue }) },
    getChance(newValue) { TweenLite.to(this.$data, 0.5, { chanceAnimatedNum: newValue }) },
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        if (typeof window.WavesKeeper !== 'undefined') {
          try {
            window.WavesKeeper.on('update', state => this.wavesKeeperData = state)
          } catch (e) { console.log(e) && location.reload() }
        }

        window.WavesKeeper.publicState().then(state => this.wavesKeeperData = state).catch(() => {
          window.WavesKeeper.auth({ name: this.t('keeper_title'), data: this.t('keeper_desc') }).then(res => {
            this.wavesKeeperData = res
          }).catch(err => console.log(err))
        })
      }
    }

    this.socket.onAny((type, payload) => {
      if (type.includes('russian_roulette/history/')) this.checkIsGameEnd(payload)
    })
  }
}
</script>


<style scoped lang="sass">
@import '@/assets/sass/ui.sass'
</style>