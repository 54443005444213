<template>
  <div class="history" v-if="history[config.mode].length > 0">
    <table>
      <thead>
        <tr>
          <th v-html="t('th_player')"></th>
          <th v-html="t('th_player_bet')"></th>
          <th v-html="t('th_player_choice')"></th>
          <th v-html="t('th_won')"></th>
        </tr>
      </thead>
      <tbody name="fade" @is="transition-group">
        <template v-for="(h, n) in history[config.mode]">
          <template v-if="n < 5">
            <tr @click="openThisGame(h)" :key="h.id" class="odd">
              <td class="data p-l-8">{{ shortAddress(h.address) }}</td>
                <td class="data centered p-l-8">{{ h.betAmount }} <img :src="require('@/assets/img/waves.svg')" alt="Waves" class="wave"></td>
                <td class="data centered bullets">
                <div class="bullets-flex">
                  <template v-for="(choiceEl, a) in h.playerChoice.split('')" tag="bullets-flex">
                    <img :key="a" v-if="1 == choiceEl" :class="{ spin_history: a+1 === h.random }" class="bulletYes" :src="require('@/assets/img/bullet-small.svg')" :alt="`Yes ${h.random} is the bullet`" />
                    <img :key="a" v-if="0 == choiceEl" :class="{ spin_history: a+1 === h.random }" class="bulletNo" :src="require('@/assets/img/bullet-small.svg')" alt="No bullet" />
                  </template>
                </div>
              </td>
              <td class="data centered p-r-8">
                <span v-if="h.result === 'submitted'" class="opacity-5">?</span>
                <span v-if="h.result === 'lost'" class="opacity-5">—</span>
                <template v-if="h.result === 'won'">
                  {{ h.winAmount }} <img :src="require('@/assets/img/waves.svg')" alt="Waves" class="wave">
                </template>
              </td>
            </tr>
            <tr :key="h.id+100" class="spacer">
              <td colspan="5" class="spacer"></td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
  <div v-else class="history_loading">
    <p>{{ t('history_loading') }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      history: {
        mainnet: [],
        testnet: []
      }
    }
  },
  methods: {
    shortAddress(address) {
      return `${address.substring(0, 4)}***${address.substring(address.length-4)}`
    },
    openThisGame(thisLastGame) {
      this.$store.commit('russian_roulette/set__modalContent', thisLastGame)
      this.$store.commit('russian_roulette/set__modal', 'gameHistory')
    },
  },
  computed: {
    socket() { return this.$store.getters['app/get__socket'] },
    config() { return this.$store.getters['russian_roulette/get__config'] }
  },
  mounted() {
    this.socket.onAny((type, payload) => {
      if (type === 'russian_roulette/history/mainnet') this.history.mainnet = payload
      if (type === 'russian_roulette/history/testnet') this.history.testnet = payload
    })
  }
}
</script>

<style scoped lang="sass">
@import '@/assets/sass/history.sass'
</style>