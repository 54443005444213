<template>
  <main class="russian_roulette">
    <div class="container">
      <div class="game">
        <UI />
        <History />
        <div class="project">WEB 3.0</div>
        <Modals />
      </div>
    </div>
  </main>
</template>

<script>
import History from '@/components/History'
import UI from '@/components/UI'
import Modals from '@/components//Modals'

export default {
  name: 'RussianRoulette',
  components: { History, UI, Modals },
}
</script>

<style lang="sass">
@import '@/assets/sass/game.sass'
</style>